





































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BAvatar, BButton, BCard, BCol, BRow} from "bootstrap-vue";
import User = EmployeeAPI.User.User;
import {avatarText} from '@/helpers/filters/avatarText';

@Component({
  components: {BCard, BButton, BRow, BCol, BAvatar}
})
export default class AccessListsInfoCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: MaintenanceAPI.AccessLists.Item;


  avatarText() {
    return avatarText(this.item?.name)
  }
}

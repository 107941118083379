














































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar,
  BButton,
  BCard, BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BFormSelect, BFormSelectOption,
  BRow
} from "bootstrap-vue";
import {NotifyInfo} from "@/helpers/Toast";

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BFormSelect,
    BFormSelectOption
  }
})
export default class AccessListsListCard extends Vue {
  @Prop({
    default: () => {
      return null;
    }, required: true
  })
  id: string

  @Prop({
    default: () => {
      return null;
    }, required: true
  })
  title: string

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  items: string[]

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  listType: string

  editingItems: string[] = []
  editing = false;

  @Watch("items", {immediate: true})
  watchItems() {
    this.$set(this, "editingItems", [...this.items]);
  }

  startEditing() {
    this.$set(this, "editing", true);
  }

  stopEditing() {
    this.$set(this, "editing", false);
    this.$emit("refetch");
  }

  addItem() {
    this.editingItems.push("");
  }

  removeItem(i) {
    this.editingItems.splice(i, 1);
  }


  save() {
    this.$emit("save", {
      type: this.listType,
      items: [...this.editingItems]
    });

    this.$set(this, "editing", false);
  }
}

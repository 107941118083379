






















import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle
} from "bootstrap-vue";

import InfoCard from "./components/info-card.vue";
import ListCard from './components/list-card.vue';


@Component({
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    InfoCard,
    ListCard
  }
})
export default class AccessList extends Vue {
  item: MaintenanceAPI.AccessLists.Item = null;

  async created() {
    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  async getItem() {
    const item = await this.$api.maintenance.accesslists.Get(this.id);
    this.$set(this, "item", item);
  }

  async saveListChanges(obj: { type: string, items: string[] }) {
    let item: MaintenanceAPI.AccessLists.Item = null;
    switch (obj.type) {
      case "whitelist":
        item = await this.$api.maintenance.accesslists.Set(this.item.type, this.item.name, obj.items, this.item.blacklist);
        break;
      case "blacklist":
        item = await this.$api.maintenance.accesslists.Set(this.item.type, this.item.name, this.item.whitelist, obj.items);
        break;
    }

    this.$set(this, "item", item);
  }
}
